import '../assets/style.css';
import '../assets/transitions.css';
import '../assets/tippy-theme.css';

const MOBILE_MEDIA_QUERY = 'screen and (max-width: 1000px)';

import(
  window.matchMedia(MOBILE_MEDIA_QUERY).matches ? './MobileApp' : './DesktopApp'
).then(value => {
  value.default.mount('#app'); 
});